
import { Swiper, Pagination } from "swiper";
import axios from "axios";
import { mapMutations } from 'vuex'; 
import UrlOgReview from "./sub/UrlOgReview.vue";
export default {
    name: "article-card",
    props: {
        article: Object,
        model_id: String,
        pa_count: Number,
        article_idx: Number,
        pa_page: String,
        close_utils: Boolean,
        see_mode: String
    },
    data() {
        return {
            collect_status: this.article.relation ? this.article.relation.collect : false,
            like_status: this.article.relation ? this.article.relation.like : false,
            show_this_article: true,
            isVisible: true,
            like_count: this.article.counts?.like_count ? this.article.counts.like_count : 0,
            comment_count: this.article.counts.comment_count ? this.article.counts.comment_count : 0,
            collect_count: this.article.counts.collect_count ? this.article.counts.collect_count : 0,
            block_click_status: false,
            pa_action_label_list: {
                '首頁': {
                    e_action: '短貼文',
                    e_label: '短貼文',
                }
            },
            random_default_img: Math.floor(Math.random() * 3),
            open_edit_status: false,
            swiper: '',
            og_url: {
                url: '',
                title: '',
                thumb: '',
                body: '',
                embed: ''
            }
        };
    },
    computed: {
        is_login() {
            return this.$store.state.login.login_status;
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        member_uniqid() {
            return this.$store.state.login.member_data.member_uniqid;
        },
        set_to_delete_article_id() {
            return this.$store.state.short_article.to_delete_short_article_id;
        },
        router_name() {
            return this.$route.name;
        },
        decode_title() {
            return decodeURIComponent(this.article.title);
        },
        router_name() {
            return this.$route.name;
        },
        day_and_month() {
            const time = new Date(this.article.datetime);
            const month = time.getMonth() + 1;
            const date = time.getDate();
            return month.toString() + '/' + date.toString();
        },
        haveImage() {
            const article_defalut_img = "https://imageproxy.pimg.tw/zoomcrop?url=https://s.pixfs.net/common/ppage/image/notfound.png&width=800&height=800";
            if (this.article.image == article_defalut_img) {
                return false;
            }
            else {
                return true;
            }
        },
        dynamic_class() {
            return this.see_mode + "_" + this.article.id;
        },
        dynamic_pagination_class() {
            return this.see_mode + "pagination_" + this.article.id;
        }
    },
    methods: {
        ...mapMutations('share', [
            'setShareLink',
            'setShareTitle',
            'toggleShareOpen',
        ]),
        ...mapMutations('report', [
            'toggleReport',
            'updateArticleId',
            'updateArticleLink',
            'updateArticleMemberId'
        ]),
        ...mapMutations('lightbox', [
            'setViewData',
        ]),
        ...mapMutations('short_article', [
            'toogleDeleteBoxStatus',
            'setToDeleteShortArticleId',
            'toggleOpenPostBlock',
            'setPostMode',
            'setEditContent'
        ]),
        openShare(link, title) {
            this.setShareLink(link);
            this.setShareTitle(title);
            this.toggleShareOpen();
        },
        async openReport(id, link, user_name) {
            this.paClickReportFunction();
            if (this.is_login && this.member_token) {
                this.updateArticleMemberId(user_name);
                this.updateArticleId(id);
                this.updateArticleLink(link);
                this.toggleReport();
            }
            else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
        openEdit() {
            this.open_edit_status = !this.open_edit_status;
        },
        editArticle() {
            this.setEditContent(this.article);
            this.setPostMode('edit');
            this.toggleOpenPostBlock();
            this.openEdit();
        },
        deleteArticle(id) {
            this.setToDeleteShortArticleId(id);
            this.toogleDeleteBoxStatus();
            this.openEdit();
        },
        collectArticle() {
            if (this.is_login && this.member_token != null) {
                const API_url = "https://www.pixnet.net/api/v1/member/collections";
                const object_ids = {
                    type: 'personal',
                    object_id: this.article.id
                };
                const reqInstance = axios.create({
                    headers: {
                        Authorization: "Bearer " + this.member_token,
                    }
                });
                const req = reqInstance.post(API_url, object_ids);
                req.then((e) => {
                    if (!e.data.error && e.status === 200) {
                        this.collect_status = true;
                        this.setViewData("collect");
                        this.openToastElement();
                        this.collect_count = parseInt(this.collect_count) + 1;
                    }
                })
                    .catch((e) => {
                    console.log(e);
                });
            }
            else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
        discollectArticle() {
            if (this.is_login && this.member_token != null) {
                const API_url = "https://www.pixnet.net/api/v1/member/collections";
                const params = {
                    type: "personal",
                    object_ids: [this.article.id],
                };
                const headers = {
                    Authorization: "Bearer " + this.member_token,
                };
                const req = axios.delete(API_url, { headers: headers, params: params, responseType: "json" });
                req.then((e) => {
                    if (!e.data.error && e.status === 200) {
                        this.collect_status = false;
                        this.setViewData("uncollect");
                        this.openToastElement();
                        this.collect_count = parseInt(this.collect_count) - 1;
                    }
                })
                    .catch((e) => {
                    console.log(e);
                });
            }
            else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
        toggleCollectArticle(status) {
            this.paClickCollectFunction();
            if (status) {
                this.discollectArticle();
            }
            else {
                this.collectArticle();
            }
        },
        likeArticle() {
            if (this.is_login && this.member_token != null) {
                const API_url = "https://www.pixnet.net/mainpage/api/pushes";
                const object_ids = {
                    service: 'personal-post',
                    comment_id: this.article.author.uniqid + '-personal://members/' + this.article.author.uniqid + '/articles/' + this.article.id + '-' + this.article.id,
                    push_count: 1
                };
                const reqInstance = axios.create({
                    headers: {
                        Authorization: "Bearer " + this.member_token,
                    }
                });
                const req = reqInstance.post(API_url, object_ids);
                req.then((e) => {
                    if (!e.data.error && e.status === 200) {
                        this.like_status = true;
                        this.like_count = parseInt(this.like_count) + 1;
                    }
                })
                    .catch((e) => {
                    console.log(e);
                });
            }
            else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
        disLikeArticle() {
            if (this.is_login && this.member_token != null) {
                const API_url = "https://www.pixnet.net/mainpage/api/pushes";
                const params = {
                    service: 'personal-post',
                    comment_id: this.article.author.uniqid + '-personal://members/' + this.article.author.uniqid + '/articles/' + this.article.id + '-' + this.article.id,
                };
                const headers = {
                    Authorization: "Bearer " + this.member_token,
                };
                const req = axios.delete(API_url, { headers: headers, params: params, responseType: "json" });
                req.then((e) => {
                    if (!e.data.error && e.status === 200) {
                        this.like_status = false;
                        this.like_count = parseInt(this.like_count) - 1;
                    }
                })
                    .catch((e) => {
                    console.log(e);
                });
            }
            else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
        setBlockToAvoidTooMoreClick() {
            this.block_click_status = true;
            setTimeout(() => {
                this.block_click_status = false;
            }, 1000);
        },
        toggleLikeArticle(status) {
            this.setBlockToAvoidTooMoreClick();
            this.paClickLikeFunction();
            if (status) {
                this.disLikeArticle();
            }
            else {
                this.likeArticle();
            }
        },
        openToastElement() {
            this.$parent.openToast();
        },
        disableThisArticle() {
            this.show_this_article = !this.show_this_article;
        },
        paFunction() {
            let pa_idx = (this.pa_count + (this.article_idx + 1) / 10000).toFixed(4);
            let reg_article_link = "http://" + this.article.author.user_name + ".pixnet.net/blog/post/" + this.article.id;
            window._piq.push([
                'trackEvent',
                `rs2022::十大頻道::${this.pa_page}`,
                `imps::動態::post_card::user_action`,
                `短貼文::${reg_article_link}`,
                pa_idx
            ]);
        },
        paClickFunction() {
            let pa_idx = (this.pa_count + (this.article_idx + 1) / 10000).toFixed(4);
            let reg_article_link = "http://" + this.article.author.user_name + ".pixnet.net/blog/post/" + this.article.id;
            window._piq.push([
                'trackEvent',
                `rs2022::十大頻道::${this.pa_page}`,
                `click::動態::post_card::user_action`,
                `短貼文::${reg_article_link}`,
                pa_idx
            ]);
        },
        paClickLikeFunction() {
            let pa_idx = (this.pa_count + (this.article_idx + 1) / 10000).toFixed(4);
            let reg_article_link = "http://" + this.article.author.user_name + ".pixnet.net/blog/post/" + this.article.id;
            window._piq.push([
                'trackEvent',
                `rs2022::十大頻道::${this.pa_page}`,
                `happix::動態::post_card::user_action`,
                `短貼文::${reg_article_link}`,
                pa_idx
            ]);
        },
        paClickCommentFunction() {
            let pa_idx = (this.pa_count + (this.article_idx + 1) / 10000).toFixed(4);
            let reg_article_link = "http://" + this.article.author.user_name + ".pixnet.net/blog/post/" + this.article.id;
            window._piq.push([
                'trackEvent',
                `rs2022::十大頻道::${this.pa_page}`,
                `comment/read::動態::post_card::user_action`,
                `短貼文::${reg_article_link}`,
                pa_idx
            ]);
        },
        paClickCollectFunction() {
            let pa_idx = (this.pa_count + (this.article_idx + 1) / 10000).toFixed(4);
            let reg_article_link = "http://" + this.article.author.user_name + ".pixnet.net/blog/post/" + this.article.id;
            window._piq.push([
                'trackEvent',
                `rs2022::十大頻道::${this.pa_page}`,
                `collect::動態::post_card::user_action`,
                `短貼文::${reg_article_link}`,
                pa_idx
            ]);
        },
        paClickShareFunction() {
            let pa_idx = (this.pa_count + (this.article_idx + 1) / 10000).toFixed(4);
            let reg_article_link = "http://" + this.article.author.user_name + ".pixnet.net/blog/post/" + this.article.id;
            window._piq.push([
                'trackEvent',
                `rs2022::十大頻道::${this.pa_page}`,
                `share::動態::post_card::user_action`,
                `短貼文::${reg_article_link}`,
                pa_idx
            ]);
        },
        paClickReportFunction() {
            let pa_idx = (this.pa_count + (this.article_idx + 1) / 10000).toFixed(4);
            let reg_article_link = "http://" + this.article.author.user_name + ".pixnet.net/blog/post/" + this.article.id;
            window._piq.push([
                'trackEvent',
                `rs2022::十大頻道::${this.pa_page}`,
                `morelist::動態::post_card::user_action`,
                `短貼文::${reg_article_link}`,
                pa_idx
            ]);
        },
        imgOnError(event) {
            event.target.src = 'https://www.pixnet.net/img/public/default/img_default_' + this.random_default_img + '.png';
        },
        visibilityChanged(isVisible, entry) {
            this.isVisible = isVisible;
            if (entry.intersectionRatio > 0) {
                this.paFunction();
            }
        },
        setSwiper() {
            const class_name = "." + this.dynamic_class;
            const pagination_class_name = "#" + this.dynamic_pagination_class;
            Swiper.use([Pagination]);
            this.swiper = new Swiper(class_name, {
                direction: "horizontal",
                slidesPerView: 1,
                slidesPerGroup: 1,
                observer: true,
                modules: [Pagination],
                pagination: {
                    el: pagination_class_name,
                    type: "bullets",
                    clickable: true,
                },
            });
        },
    },
    mounted() {
        this.setSwiper();
        // console.log(this.member_token)
    },
    components: { UrlOgReview }
};
